import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const SupplierModal = ({ handleClose, selectedSupplier }) => {
    console.log(selectedSupplier);
    const inputDataStructure = {
        title: {
            key: "title",
            label: "Title",
            data: "",
            required: true,
            type: "text",
            error: null,
        },
        phoneNumber: {
            key: "phoneNumber",
            label: "Phone Number",
            data: "",
            required: true,
            type: "text",
            error: null,
        },
        address: {
            key: "address",
            label: "Address",
            data: "",
            required: true,
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        console.log(selectedSupplier);
        if (selectedSupplier) {
            let input_list = { ...inputs };

            input_list.title.data = selectedSupplier.title;
            input_list.remarks.data = selectedSupplier.remarks;
            input_list.phoneNumber.data = selectedSupplier.phoneNumber;
            input_list.address.data = selectedSupplier.address;

            setInputs(input_list);
        }
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            title: inputs.title.data,
            phoneNumber: inputs.phoneNumber.data,
            address: inputs.address.data,
            remarks: inputs.remarks.data,
        };

        try {
            if (selectedSupplier) {
                formData.id = selectedSupplier.id;
                await apiService.patch("supplier", formData);
                toast.success("Supplier updated successfully!");
            } else {
                await apiService.post("supplier", formData);
                toast.success("Supplier added successfully!");
            }

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">
                            {" "}
                            {!selectedSupplier && "Add new "}
                            {selectedSupplier && "Edit "}
                            Supplier
                        </h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Input
                            input={inputs.title}
                            handleChange={handleChange}
                        />

                        <Input
                            input={inputs.phoneNumber}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.address}
                            handleChange={handleChange}
                        />

                        {/* <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        /> */}
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedSupplier ? "Edit" : "Create"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierModal;
