import React, { useEffect, useState } from "react";
import Joi from "joi";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedProduct }) => {
    const inputDataStructure = {
        title: {
            key: "title",
            required: true,
            label: "Product title",
            data: "",
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
        unitPrice: {
            key: "unitPrice",
            label: "Unit Price (LKR)",
            data: "",
            type: "number",
            min: 0,
            error: null,
        },
        contractorFee: {
            key: "contractorFee",
            label: "Contractor fee (LKR)",
            data: "",
            type: "number",
            min: 0,
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = async (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        if (selectedProduct) {
            let input_list = { ...inputs };

            input_list.title.data = selectedProduct.title;
            input_list.remarks.data = selectedProduct.remarks;
            input_list.unitPrice.data = selectedProduct.unitPrice;
            input_list.contractorFee.data = selectedProduct.contractorFee;

            setInputs(input_list);
        }
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const data = {
            title: inputs.title.data,
            unitPrice: inputs.unitPrice.data,
            contractorFee: inputs.contractorFee.data,
            remarks: inputs.remarks.data,
        };

        try {
            if (selectedProduct) {
                data.id = selectedProduct.id;
                await apiService.patch("product", data);
                toast.success("Product updated successfully!");
            } else {
                await apiService.post("product", data);
                toast.success("Product added successfully!");
            }

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">
                            {!selectedProduct && "Add new "}
                            {selectedProduct && "Edit "}Product
                        </h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Input
                            input={inputs.title}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.unitPrice}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.contractorFee}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        />
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedProduct ? "Edit" : "Create"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
