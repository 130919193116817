import React, { useEffect, useState } from "react";
import Joi from "joi";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";

const JobMaterialModal = ({ handleClose, selectedJob }) => {
    const [materials, setMaterials] = useState([]);
    const [materialSummary, setMaterialSummary] = useState({});

    const getMaterials = async () => {
        let materialList = await apiService.get(
            `product/material/${selectedJob.id}`
        );
        setMaterials(materialList);
    };

    useEffect(() => {
        getMaterials();
    }, []);

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "Material ID",
            dataIndex: "materialId",
            key: "materialId",
            align: "center",
            width: "20%",
            sorter: (a, b) => a.materialId.localeCompare(b.materialId),
        },
        {
            title: "Material",
            dataIndex: "materialTitle",
            key: "materialTitle",
            align: "center",
            width: "20%",
            sorter: (a, b) => a.materialTitle.localeCompare(b.materialTitle),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: "20%",
            sorter: (a, b) => a.quantity.localeCompare(b.quantity),
        },
        {
            title: "Unit price",
            align: "center",
            width: "10%",
            render: (arg) => {
                // const formattedPrice = arg.materialUnitPrice.toLocaleString(
                //     "en-US",
                //     {
                //         style: "currency",
                //         currency: "LKR",
                //     }
                // );
                // return formattedPrice;
            },
        },
        {
            title: "Total",
            align: "center",
            width: "10%",
            render: (arg) => {
                // const total = arg.materialUnitPrice * arg.quantity;
                // const formattedPrice = total.toLocaleString("en-US", {
                //     style: "currency",
                //     currency: "LKR",
                // });
                // return formattedPrice;
            },
        },
    ];

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[60%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Material center</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4 flex gap-3">
                        <div className="w-[80%]">
                            <div className="w-full">
                                <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center">
                                    Material list
                                </h3>
                                <Table
                                    columns={columns}
                                    dataSource={materials}
                                />
                            </div>
                        </div>
                        <div className="w-[20%]">
                            <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center mb-4">
                                Material summary
                            </h3>
                            <div className="mx-4 mb-4 flex flex-col gap-y-3">
                                <p>
                                    Make :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {selectedJob.makeQuantity}
                                    </span>
                                </p>
                                <p>
                                    Given :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {materialSummary.totalGiven}
                                    </span>
                                </p>
                                <p>
                                    To be given :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {selectedJob.makeQuantity -
                                            materialSummary.totalGiven}
                                    </span>
                                </p>
                                <p>
                                    Completed :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {materialSummary.totalCompleted}
                                    </span>
                                </p>
                                <p>
                                    Pending :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {materialSummary.totalGiven -
                                            materialSummary.totalCompleted}
                                    </span>
                                </p>
                                <p>
                                    Paid :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {materialSummary.totalPaid}
                                    </span>
                                </p>
                                <p>
                                    To be paid :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {materialSummary.totalCompleted -
                                            materialSummary.totalPaid}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobMaterialModal;
