import React from "react";

import ListItem from "./listItem";

import {
    FaHome,
    FaBars,
    FaDollyFlatbed,
    FaPowerOff,
    FaShoppingCart,
    FaCashRegister,
    FaCog,
} from "react-icons/fa";

const SideBar = ({ section }) => {
    const navList = [
        {
            title: "Home",
            url: "/",
            icon: <FaHome />,
            selected: section === "home",
        },
        {
            title: "Contractors",
            url: "/contractors",
            icon: <FaCashRegister />,
            selected: section === "contractors",
        },
        {
            title: "Suppliers",
            url: "/suppliers",
            icon: <FaCashRegister />,
            selected: section === "suppliers",
        },
        {
            title: "Customers",
            url: "/customers",
            icon: <FaCashRegister />,
            selected: section === "customers",
        },
        {
            title: "Materials",
            url: "/materials",
            icon: <FaBars />,
            selected: section === "materials",
        },
        {
            title: "Products",
            url: "/products",
            icon: <FaDollyFlatbed />,
            selected: section === "products",
        },
        {
            title: "Jobs",
            url: "/jobs",
            icon: <FaDollyFlatbed />,
            selected: section === "jobs",
        },
        {
            title: "POS",
            url: "/pos",
            icon: <FaCashRegister />,
            selected: section === "pos",
        },
        {
            title: "Sales",
            url: "/sales",
            icon: <FaShoppingCart />,
            selected: section === "sales",
        },
        {
            title: "Payments",
            url: "/payments",
            icon: <FaShoppingCart />,
            selected: section === "payments",
        },
    ];

    return (
        <ul className="space-y-4 mb-12 mt-20 w-full">
            {navList.map((item) => (
                <ListItem key={item.url} item={item} />
            ))}
        </ul>
    );
};
export default SideBar;
