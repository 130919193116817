import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const JobModal = ({ handleClose, selectedJob }) => {
    const inputDataStructure = {
        product: {
            key: "product",
            required: true,
            label: "Product",
            data: "",
            optList: [],
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },
        quantity: {
            key: "quantity",
            label: "Quantity",
            data: "",
            min: 0,
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = async (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        let products = await apiService.get("product");

        products = products.map((product) => {
            return { id: product.id, title: product.productId };
        });

        input_list.product.optList = products;

        if (selectedJob) {
            input_list.title.data = selectedJob.title;
            input_list.remarks.data = selectedJob.remarks;
            input_list.quantity.data = selectedJob.quantity;
            input_list.unitPrice.data = selectedJob.unitPrice;
        }

        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            product: inputs.product.data,
            quantity: inputs.quantity.data,
            remarks: inputs.remarks.data,
        };

        if (!inputs.product.data) {
            toast.error("Please select a product!");
            return;
        }

        if (inputs.quantity.data <= 0) {
            toast.error("Please enter a valid quantity!");
            return;
        }

        try {
            await apiService.post("job", formData);
            toast.success("Job added successfully!");

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">New job</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Select
                            input={inputs.product}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.quantity}
                            handleChange={handleChange}
                        />

                        {/* <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        /> */}
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedJob ? "Edit" : "Create"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobModal;
