import React from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

import HomeSection from "./Home";

import Materials from "./Materials";
import Contractors from "./Contractors";
import Suppliers from "./Suppliers";
import Products from "./Products";
import Jobs from "./Jobs";
import Pos from "./Pos";
import Sales from "./Sales";
import Payments from "./Payments";
import Customers from "./Customers";

const Dashboard = ({ section }) => {
    return (
        <div>
            <NavBar dashboard={true} />
            <div className="flex mt-[70px] gap-6">
                <div className="w-[220px] p-10 bg-primary fixed h-screen -mt-[70px]">
                    <SideBar section={section} />
                </div>
                <div className="w-full ml-[220px] ">
                    {section === "home" && <HomeSection />}
                    {section === "materials" && <Materials />}
                    {section === "contractors" && <Contractors />}
                    {section === "customers" && <Customers />}
                    {section === "suppliers" && <Suppliers />}
                    {section === "products" && <Products />}
                    {section === "jobs" && <Jobs />}
                    {section === "payments" && <Payments />}
                    {section === "pos" && <Pos />}
                    {section === "sales" && <Sales />}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
