import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import Moment from "moment";
import JobModal from "./JobModal";
import JobMaterialModal from "./JobMaterialModal";
import JobExportModal from "./JobExportModal";
import JobContractModal from "./JobContractModal";

import { Button, Confirm, Input } from "../../../components/ui";
import apiService from "../../../http";

const Jobs = () => {
    const [jobs, setJobs] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [jobModalState, setJobModalState] = useState(false);
    const [materialModalState, setMaterialModalState] = useState(false);
    const [exportModalState, setExportModalState] = useState(false);
    const [contractModalState, setContractModalState] = useState(false);

    const [selectedJob, setSelectedJob] = useState(null);
    const [confirmModalState, setConfirmModalState] = useState(false);
    const inputDataStructure = {
        searchString: {
            key: "searchString",
            label: "",
            data: "",
            type: "text",
            placeholder: "Search an item",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleCloseModal = () => {
        setJobModalState(false);
        setConfirmModalState(false);
        setMaterialModalState(false);
        setContractModalState(false);
        setExportModalState(false);
        refreshJobs();
    };

    const refreshJobs = async () => {
        try {
            const jobs = await apiService.get("job");

            setJobs(jobs);
            setFilteredData(jobs);
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.data === "") {
            setFilteredData(jobs);
        } else {
            const data = jobs.filter((item) => {
                const lowerCaseTitle = item.product.title.toLowerCase();
                const lowerCaseInputData = input.data.toLowerCase();
                return lowerCaseTitle.includes(lowerCaseInputData);
            });

            setFilteredData(data);
        }

        setInputs(input_list);
    };

    useEffect(() => {
        refreshJobs();
    }, []);

    const jobStatus = (job) => {
        let classes =
            "cursor-pointer inline-flex items-center justify-center px-2  py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full";
        let status;
        if (job.makeQuantity === job.finishedQuantity) {
            status = "Finished";
            classes += " bg-[#1abc9c]";
        } else {
            status = "On Going";
            classes += " bg-[#6c5ce7]";
        }

        return <span className={classes}>{status}</span>;
    };

    const actionBtns = (job) => {
        return (
            <div className="flex items-center flex-wrap">
                {/* <Button
                    disabled={job.status === "finished"}
                    text="Parts"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedJob(job);
                        setPartModalState(true);
                    }}
                /> */}
                <Button
                    disabled={job.status === "finished"}
                    text="Contracts"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedJob(job);
                        setContractModalState(true);
                    }}
                />
                <Button
                    disabled={job.status === "finished"}
                    text="Materials"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedJob(job);
                        setMaterialModalState(true);
                    }}
                />
                <Button
                    disabled={job.status === "finished"}
                    text="Export"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedJob(job);
                        setExportModalState(true);
                    }}
                />
            </div>
        );
    };

    const columns = [
        {
            title: "Job ID",
            dataIndex: "jobId",
            key: "jobId",
            align: "center",
            width: "5%",
            sorter: (a, b) => a.jobId.localeCompare(b.jobId),
        },
        {
            title: "Product Id",
            align: "center",
            width: "5%",
            render: (arg) => {
                return arg.product.productId;
            },
        },
        {
            title: "Product Title",
            align: "center",
            width: "5%",
            render: (arg) => {
                return arg.product.title;
            },
        },

        {
            title: "Unit Price",
            align: "center",
            width: "5%",
            render: (arg) => {
                const formattedPrice = arg.product.unitPrice.toLocaleString(
                    "en-US",
                    {
                        minimumFractionDigits: 2,
                    }
                );
                return formattedPrice;
            },
        },
        {
            title: "Contractor Fee",
            align: "center",
            width: "5%",
            render: (arg) => {
                const formattedPrice = arg.product.contractorFee.toLocaleString(
                    "en-US",
                    {
                        minimumFractionDigits: 2,
                    }
                );
                return formattedPrice;
            },
        },
        {
            title: "Make Quantity",
            align: "center",
            width: "3%",
            dataIndex: "makeQuantity",
            key: "makeQuantity",
        },
        {
            title: "Finished Quantity",
            align: "center",
            width: "3%",
            dataIndex: "finishedQuantity",
            key: "finishedQuantity",
        },

        {
            title: "Status",
            align: "center",
            width: "15%",
            render: (arg) => jobStatus(arg),
        },
        {
            title: "Operations",
            align: "center",
            width: "15%",
            render: (arg) => actionBtns(arg),
        },
    ];

    return (
        <section className="w-full mt-6">
            {jobModalState && (
                <JobModal
                    selectedJob={selectedJob}
                    handleClose={handleCloseModal}
                />
            )}
            {materialModalState && (
                <JobMaterialModal
                    selectedJob={selectedJob}
                    handleClose={handleCloseModal}
                />
            )}
            {exportModalState && (
                <JobExportModal
                    selectedJob={selectedJob}
                    handleClose={handleCloseModal}
                />
            )}
            {contractModalState && (
                <JobContractModal
                    selectedJob={selectedJob}
                    handleClose={handleCloseModal}
                />
            )}
            {confirmModalState && (
                <Confirm
                    title="Finish job"
                    confirmMsg="Do you want to finish the job job?"
                    actionLabel="OK"
                    cancelHandler={handleCloseModal}
                    confirmHandler={async () => {
                        await apiService.patch("job/status/" + selectedJob.id);
                        toast.success("Job status changed successfully!");
                        handleCloseModal();
                        setSelectedJob(null);
                    }}
                />
            )}

            <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
                <div>
                    <Button
                        text={"New Job"}
                        handleClick={() => setJobModalState(true)}
                    />
                </div>
                <div></div>
                <div>
                    <Input
                        input={inputs.searchString}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={filteredData}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Jobs;
