import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Moment from "moment";
import apiService from "../../../http";
import { Input } from "../../../components/ui";

const Sales = () => {
    const [sales, setSales] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const inputDataStructure = {
        searchString: {
            key: "searchString",
            label: "",
            data: "",
            type: "text",
            placeholder: "Search an item",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const refreshSales = async () => {
        try {
            const sales = await apiService.get("sale");
            setSales(sales);
            setFilteredData(sales);
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.data === "") {
            setFilteredData(sales);
        } else {
            const data = sales.filter((item) => {
                const lowerCaseTitle = item.customerName.toLowerCase();
                const lowerCaseInputData = input.data.toLowerCase();
                return lowerCaseTitle.includes(lowerCaseInputData);
            });

            setFilteredData(data);
        }

        setInputs(input_list);
    };

    useEffect(() => {
        refreshSales();
    }, []);

    const columns = [
        {
            title: "Sale Id",
            dataIndex: "saleId",
            key: "saleId",
            align: "center",
            width: "5%",
            sorter: (a, b) => a.saleId.localeCompare(b.saleId),
        },
        {
            title: "Customer Name",
            align: "left",
            width: "20%",
            render: (arg) => {
                return (
                    <div className="flex flex-col items-start">
                        <p>{arg.customerName}</p>
                    </div>
                );
            },
        },

        {
            title: "Discount",
            align: "center",
            width: "5%",
            render: (arg) => {
                return arg.discount + "%";
            },
            sorter: (a, b) => a.discount - b.discount,
        },

        {
            title: "Total",
            align: "center",
            width: "10%",
            render: (arg) => {
                const formattedPrice = arg.total.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                });
                return formattedPrice;
            },
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: "Sub Total",
            align: "center",
            width: "10%",
            render: (arg) => {
                const subTotal = arg.total - (arg.total * arg.discount) / 100;
                const formattedPrice = subTotal.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                });
                return formattedPrice;
            },
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: "Date",
            align: "center",
            width: "10%",
            render: (arg) => {
                const date = Moment(arg.createdAt);

                const formattedDatetime = date.format("YYYY-MM-DD");

                return formattedDatetime;
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: "Invoice",
            align: "center",
            width: "10%",
            render: (arg) => {
                return (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={arg.invoice}
                        className="bg-primary px-2 text-white py-1 rounded-lg"
                    >
                        view
                    </a>
                );
            },
        },
    ];

    return (
        <section className="w-full mt-6">
            <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
                <div></div>
                <div></div>
                <div>
                    <Input
                        input={inputs.searchString}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={filteredData}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Sales;
