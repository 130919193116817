import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import ProductModal from "./ProductModal";
import ProductMaterialModal from "./ProductMaterialModal";
import ProductStockModal from "./ProductStockModal";

import { Button, Confirm, Input } from "../../../components/ui";
import apiService from "../../../http";

const Products = () => {
    const [products, setProducts] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const inputDataStructure = {
        searchString: {
            key: "searchString",
            label: "",
            data: "",
            type: "text",
            placeholder: "Search an item",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const [productModalState, setProductModalState] = useState(false);
    const [materialModalState, setMaterialModalState] = useState(false);
    const [productStockModal, setProductStockModalState] = useState(false);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [confirmModalState, setConfirmModalState] = useState(false);

    const handleCloseModal = () => {
        setSelectedProduct(null);
        setProductModalState(false);
        setConfirmModalState(false);
        setMaterialModalState(false);
        setProductStockModalState(false);
        refreshProducts();
    };

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        if (input.data === "") {
            setFilteredData(products);
        } else {
            const data = products.filter((item) => {
                const lowerCaseTitle = item.title.toLowerCase();
                const lowerCaseInputData = input.data.toLowerCase();
                return lowerCaseTitle.includes(lowerCaseInputData);
            });

            setFilteredData(data);
        }

        setInputs(input_list);
    };

    const refreshProducts = async () => {
        try {
            const products = await apiService.get("product");

            setProducts(products);
            setFilteredData(products);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        refreshProducts();
    }, []);

    const actionBtns = (product) => {
        return (
            <div className="flex items-center flex-wrap">
                {/* <Button
                    text="Delete"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedProduct(product);
                        // setPartModalState(true);
                    }}
                /> */}

                <Button
                    text="Edit"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedProduct(product);
                        setProductModalState(true);
                    }}
                />
                <Button
                    text="Materials"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedProduct(product);
                        setMaterialModalState(true);
                    }}
                />
                <Button
                    text="Stock"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => {
                        setSelectedProduct(product);
                        setProductStockModalState(true);
                    }}
                />
            </div>
        );
    };

    const columns = [
        {
            title: "Product ID",
            dataIndex: "productId",
            key: "productId",
            align: "center",
            width: "5%",
            sorter: (a, b) => a.productId.localeCompare(b.productId),
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "10%",
            // sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
            title: "Unit Price",
            align: "center",
            width: "5%",
            render: (arg) => {
                const formattedPrice = arg.unitPrice.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                });
                return formattedPrice;
            },
            // sorter: (a, b) => a.total - b.total,
        },
        {
            title: "Contractor Fee",
            align: "center",
            width: "5%",
            render: (arg) => {
                const formattedPrice = arg.contractorFee.toLocaleString(
                    "en-US",
                    {
                        minimumFractionDigits: 2,
                    }
                );
                return formattedPrice;
            },
            // sorter: (a, b) => a.total - b.total,
        },
        {
            title: "Stock Quantity",
            dataIndex: "stock",
            align: "center",
            width: "5%",
            // sorter: (a, b) => a.stock - b.stock,
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            align: "center",
            width: "10%",
        },

        {
            title: "Operations",
            align: "center",
            width: "20%",
            render: (arg) => actionBtns(arg),
        },
    ];

    return (
        <section className="w-full mt-6">
            {productModalState && (
                <ProductModal
                    selectedProduct={selectedProduct}
                    handleClose={handleCloseModal}
                />
            )}
            {materialModalState && (
                <ProductMaterialModal
                    selectedProduct={selectedProduct}
                    handleClose={handleCloseModal}
                />
            )}
            {productStockModal && (
                <ProductStockModal
                    selectedProduct={selectedProduct}
                    handleClose={handleCloseModal}
                />
            )}

            {confirmModalState && (
                <Confirm
                    title="Finish Job"
                    confirmMsg="Do you want to finish the product job?"
                    actionLabel="OK"
                    cancelHandler={handleCloseModal}
                    confirmHandler={async () => {
                        await apiService.patch(
                            "product/status/" + selectedProduct.id
                        );
                        toast.success("Product status changed successfully!");
                        handleCloseModal();
                        setSelectedProduct(null);
                    }}
                />
            )}

            <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
                <div>
                    <Button
                        text={"New Product"}
                        handleClick={() => setProductModalState(true)}
                    />
                </div>
                <div></div>
                <div>
                    <Input
                        input={inputs.searchString}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={filteredData}
                style={{ width: "100%" }}
            />
        </section>
    );
};

export default Products;
