import React, { useEffect, useState } from "react";
import Moment from "moment";
import { Input, Button, Select, Confirm } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";

const JobContractModal = ({ handleClose, selectedJob }) => {
    const inputDataStructure = {
        title: {
            key: "title",
            required: true,
            label: "Part title",
            data: "",
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },

        quantity: {
            key: "quantity",
            label: "Quantity",
            required: true,
            min: 0,
            data: 0,
            type: "number",
            error: null,
        },

        type: {
            key: "type",
            label: "Process Type",
            required: true,
            optTitle: "a type",
            optList: [
                { id: "assign", title: "Assign to Contractor" },
                { id: "return", title: "Return from Contractor" },
                { id: "reject", title: "Reject to Contractor" },
            ],
            data: null,
            error: null,
        },
        contractor: {
            key: "contractor",
            label: "Contractor",
            required: true,
            optTitle: "a contractor",
            optList: [],
            data: null,
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [transactions, setTransactions] = useState([]);
    const [selectedContractor, setSelectedContractor] = useState([]);
    const [confirmModalState, setConfirmModalState] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState([]);
    const [transactionSummary, setTransactionSummary] = useState({});
    const [tab, setTab] = useState("status");

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;
        setInputs(input_list);
    };

    const getContractors = async () => {
        let contractors = await apiService.get(`contractor`);

        let input_list = { ...inputs };

        contractors = contractors.map((contractor) => {
            return { id: contractor.id, title: contractor.title };
        });

        input_list.contractor.optList = contractors;
        setInputs(input_list);
    };

    const buildContractStatus = (transactionList, paymentList) => {
        let transactionStatusList = [];

        let totalGiven = 0;
        let totalCompleted = 0;
        let totalPaid = 0;

        transactionList.forEach((transaction) => {
            let index = transactionStatusList.findIndex((item) => {
                return item.contractor.id === transaction.contractor.id;
            });

            if (index === -1) {
                const newLen = transactionStatusList.push({
                    given: 0,
                    completed: 0,
                    paid: 0,
                    contractor: transaction.contractor,
                });

                index = newLen - 1;
            }

            switch (transaction.type) {
                case "assign":
                    transactionStatusList[index].given += transaction.quantity;
                    totalGiven += transaction.quantity;
                    break;
                case "return":
                    transactionStatusList[index].completed +=
                        transaction.quantity;
                    totalCompleted += transaction.quantity;
                    break;
                case "reject":
                    transactionStatusList[index].completed -=
                        transaction.quantity;
                    totalCompleted -= transaction.quantity;
                    break;

                default:
                    break;
            }
        });
        paymentList.forEach((payment) => {
            totalPaid += payment.quantity;

            let index = transactionStatusList.findIndex((item) => {
                return item.contractor.id === payment.contractor.id;
            });
            transactionStatusList[index].paid += payment.quantity;
        });
        setTransactionStatus(transactionStatusList);

        const summary = {
            totalGiven,
            totalCompleted,
            totalPaid,
        };

        setTransactionSummary(summary);
    };

    const getContractData = async () => {
        const transactionList = await apiService.get(
            `transaction/${selectedJob.id}`
        );
        const paymentList = await apiService.get(`payment/${selectedJob.id}`);

        setTransactions(transactionList);

        buildContractStatus(transactionList, paymentList);
    };

    useEffect(() => {
        getContractors();
        getContractData();
    }, []);

    const handleTransaction = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            job: selectedJob.id,
            quantity: inputs.quantity.data,
            type: inputs.type.data,
            contractor: inputs.contractor.data,
        };

        try {
            await apiService.post("transaction", formData);
            toast.success("Transaction completed successfully!");
            getContractData();
        } catch (e) {
            toast.error(e);
        }
    };

    const handlePayment = async () => {
        try {
            await apiService.post("payment", {
                job: selectedJob.id,
                contractor: selectedContractor,
            });

            toast.success("Payment completed successfully!");
            getContractData();
        } catch (e) {
            toast.error(e);
        }

        setConfirmModalState(false);
    };

    const actionBtns = (arg) => {
        return (
            <div className="flex">
                <Button
                    text="Payment"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={async () => {
                        if (arg.completed - arg.paid) {
                            setSelectedContractor(arg.contractor.id);
                            setConfirmModalState(true);
                        } else {
                            toast.error(
                                "Total payable quantity should be greater than zero"
                            );
                        }
                    }}
                />
            </div>
        );
    };

    const transactionType = (type) => {
        let classes =
            "capitalize  px-2  py-1 mr-2 text-sm text-white rounded-lg";

        switch (type) {
            case "assign":
                classes += " bg-primary";
                break;
            case "return":
                classes += " bg-primary";
                break;
            case "reject":
                classes += " bg-primary";
                break;
            default:
        }

        return (
            <div>
                <span className={classes}>{type}</span>
            </div>
        );
    };

    const statusColumns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "Contractor id",
            align: "center",
            width: "20%",
            render: (arg) => {
                return arg.contractor.contractorId;
            },
        },
        {
            title: "Contractor title",
            align: "center",
            width: "20%",
            render: (arg) => {
                return arg.contractor.title;
            },
        },

        {
            title: "Given Quantity",
            dataIndex: "given",
            key: "given",
            align: "center",
            width: "10%",
        },
        {
            title: "Completed Quantity",
            dataIndex: "completed",
            key: "completed",
            align: "center",
            width: "10%",
        },
        {
            title: "Pending Quantity",
            align: "center",
            width: "10%",
            render: (arg) => {
                return arg.given - arg.completed;
            },
        },
        {
            title: "Paid Quantity",
            dataIndex: "paid",
            key: "paid",
            align: "center",
            width: "10%",
        },
        {
            title: "Actions",
            align: "center",
            width: "20%",
            render: (arg) => actionBtns(arg),
        },
    ];
    const transactionColumns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "Contractor id",
            align: "center",
            width: "20%",
            render: (arg) => {
                return arg.contractor.contractorId;
            },
        },
        {
            title: "Contractor title",
            align: "center",
            width: "20%",
            render: (arg) => {
                return arg.contractor.title;
            },
        },
        {
            title: "Transaction type",
            align: "center",
            width: "10%",
            render: (arg) => transactionType(arg.type),
        },
        {
            title: "Transaction date",
            align: "center",
            width: "20%",
            render: (arg) => {
                const date = Moment(arg.createdAt);

                const formattedDatetime = date.format("YYYY-MM-DD HH:mm:ss");

                return formattedDatetime;
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: "20%",
        },
    ];

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            {confirmModalState && (
                <Confirm
                    title="Make payment"
                    actionLabel="Ok"
                    confirmMsg="Are you sure , you want to make the payment?"
                    confirmHandler={handlePayment}
                    cancelHandler={() => {
                        setConfirmModalState(false);
                    }}
                />
            )}

            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[80%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Contract center</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <div className="grid grid-cols-2 gap-1 mb-2">
                            <div
                                className={`w-full py-2 ${
                                    tab === "status"
                                        ? "bg-slate-300"
                                        : "bg-slate-200"
                                }  hover:bg-secondary text-secondary  hover:text-white text-center cursor-pointer rounded-l-md`}
                                onClick={() => {
                                    setTab("status");
                                }}
                            >
                                Contract status
                            </div>
                            <div
                                className={`w-full py-2 ${
                                    tab === "transactions"
                                        ? "bg-slate-300"
                                        : "bg-slate-200"
                                } hover:bg-secondary text-secondary hover:text-white text-center cursor-pointer rounded-r-md`}
                                onClick={() => {
                                    setTab("transactions");
                                }}
                            >
                                Transactions
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="w-full">
                                {tab === "transactions" && (
                                    <Table
                                        columns={transactionColumns}
                                        dataSource={transactions}
                                    />
                                )}
                                {tab === "status" && (
                                    <Table
                                        columns={statusColumns}
                                        dataSource={transactionStatus}
                                    />
                                )}
                            </div>

                            <div className="w-[20%] ">
                                <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center mb-4">
                                    Summery
                                </h3>
                                <div className="mx-4 mb-4 flex flex-col gap-y-3">
                                    <p>
                                        Make :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {selectedJob.makeQuantity}
                                        </span>
                                    </p>
                                    <p>
                                        Given :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {transactionSummary.totalGiven}
                                        </span>
                                    </p>
                                    <p>
                                        To be given :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {selectedJob.makeQuantity -
                                                transactionSummary.totalGiven}
                                        </span>
                                    </p>
                                    <p>
                                        Completed :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {transactionSummary.totalCompleted}
                                        </span>
                                    </p>
                                    <p>
                                        Pending :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {transactionSummary.totalGiven -
                                                transactionSummary.totalCompleted}
                                        </span>
                                    </p>
                                    <p>
                                        Paid :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {transactionSummary.totalPaid}
                                        </span>
                                    </p>
                                    <p>
                                        To be paid :
                                        <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                            {transactionSummary.totalCompleted -
                                                transactionSummary.totalPaid}
                                        </span>
                                    </p>
                                </div>
                                <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center mb-4">
                                    New operation
                                </h3>
                                <Select
                                    input={inputs.type}
                                    handleChange={handleChange}
                                />
                                <Select
                                    input={inputs.contractor}
                                    handleChange={handleChange}
                                />

                                <Input
                                    input={inputs.quantity}
                                    handleChange={handleChange}
                                />

                                <Input
                                    input={inputs.remarks}
                                    handleChange={handleChange}
                                />
                                <Button
                                    handleClick={handleTransaction}
                                    className="mt-2 w-full"
                                    text={"Complete"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobContractModal;
